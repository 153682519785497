// Some basic settings
// fb app id can be blank/removed if not needed

// nav bar is set at the top of the components/nav file, not here

module.exports = {
  favicon: '/plp-favicon.ico',
  siteTitle: 'ProLabPrints.com by Fromex Photo Lab, Inc.',
  siteDescription: 'We are an online only photo lab located in San Diego, CA.',
  siteURL: 'https://prolabprints.com',
  plausibleProperty: 'prolabprints.com',
  businessName: 'Fromex Photo Lab, Inc.',
  streetAddress: '6370 El Cajon Boulevard',
  city: 'San Diego',
  state: 'CA',
  zip: '92115',
  country: 'USA',
  latitude: '32.765137',
  longitude: '-117.061386',
  phone: '619-287-5346',
  email: 'info@prolabprints.com',
  hours: 'Monday - Friday 9-5 PT, closed most holidays',
  author: 'Fromex Photo Lab, Inc.',
  dctermsrightsHolder: 'ProLabPrints.com and Fromex Photo Lab, Inc.',
  fbapp_id: '767572333356577',
  ogImage: 'https://prolabprints.com/images/fromex-og-1009-671.jpg',
}
// siteUrl is in the sitemap
// plausibleProperty is in the tracker
