import Nav from './Nav'
import Footer from './Footer'

export default function Layout({ children }) {
  return (
    <>
      <Nav />
      <main className="container mx-auto mt-10 grow px-6 2xl:max-w-[1280px]">
        {/* <div className="mx-auto mb-4 rounded bg-green-600 p-2 py-4 pl-4 text-white">
          <p>
            Holiday Hours: Tuesday 12/24/2024 9am to 3pm. Normal hours on
            Thursday 12/26, Friday 12/27 and Monday 12/30. New Years Eve 12/31
            9am to 2pm. Back to normal on 1/2/2023.
          </p>
          <p>Merry Christmas and Happy New Year!</p>
        </div> */}
        {children}
      </main>
      <Footer />
    </>
  )
}
